<template>
  <a-card :border="false">
    <div class="realtor">
      <!-- 查询表单 -->
      <a-form-model ref="searchForm" layout="horizontal" :label-col="{ xs: 8 }" :wrapper-col="{ xs: 16 }">
        <a-row :gutter="8">
          <a-col :md="8" :xl="6" :xxl="4" :sm="12">
            <a-form-model-item label="名称">
              <a-input v-model="searchData.search" placeholder="名称" allowClear></a-input>
            </a-form-model-item>
          </a-col>
          <a-col :xxl="4" :xl="5" :md="8" sm="2">
            <a-form-model-item :label-col="{ span: 0 }" :wrapper-col="{ span: 24 }">
              <a-button type="primary" @click="getDataFilter()" icon="search">查询</a-button>
              <a-button type="default" @click="reset()" icon="sync">重置</a-button>
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-form-model>
      <div>
        <a-button type="primary" @click="toHandler('add')">新增</a-button>
        <a-button type="primary" @click="toHandler('edit')">修改</a-button>
        <a-button type="primary" @click="toHandler('del')">删除</a-button>
        <a-button type="primary" @click="onRecommend">推荐至首页</a-button>
        <a-button
          v-if="selectedRowKeys.length == 0 || (selectedRows.length > 0 && selectedRows[0].flagUp == 2)"
          type="default"
          @click="onStatusChange('up')"
          icon="check-circle"
        >上架</a-button
        >
        <a-button
          v-if="selectedRowKeys.length == 0 || (selectedRows.length > 0 && selectedRows[0].flagUp == 1)"
          type="default"
          @click="onStatusChange('down')"
          icon="stop"
        >下架</a-button
        >
      </div>
      <!-- 数据表格 -->
      <a-table
        :loading="tableLoading"
        :row-selection="rowSelection"
        :rowKey="(record) => record.id"
        :columns="columns"
        :data-source="tableData"
        bordered
        :pagination="page"
        @change="changeTable"
        :customRow="changeTableRow"
      >
        <span slot="index" slot-scope="text, row, index">{{ index + 1 }}</span>
        <span slot="imageUrl" slot-scope="item">
                <ZoomMedia style="height: 20px" :value="item"></ZoomMedia>
              </span>
        <span slot="flagUp" slot-scope="text">
          <a-tag v-if="text == 1" color="#67c23a">上架</a-tag>
          <a-tag v-if="text == 2" color="#909399">下架</a-tag>
          <a-tag v-if="text == 3" color="#909399">强制下架</a-tag>
        </span>
        <!-- <span slot="action" slot-scope="text, row">
                       <a>编辑</a>
                       <a-divider type="vertical" />
                       <a>删除</a>
                     </span> -->
      </a-table>
      <!-- 编辑表单 -->
      <Article-edit-modal ref="ArticleEditModal" @reload="getData"></Article-edit-modal>
    </div>
  </a-card>
</template>

<script>
import { columns } from '../components/colums.js'
import ArticleEditModal from '../components/ArticleEditModal.vue'
import { delArticle, listArticle, selectByIdArticle, getIdByType } from '../api/ArticleApi'

const pageSource = {
  current: 1,
  pageSize: 10,
  total: 0,
}
export default {
  name: 'article',
  components: {
    ArticleEditModal,
  },
  data() {
    return {
      columns: columns,
      tableData: [],
      searchData: {}, // 搜索条件
      scroll: { y: 600 },
      tableLoading: false, //表格loading
      page: {
        current: 1,
        pageSize: 10,
        total: 0,
        showSizeChanger: true,
        showTotal: () => `共${this.page.total}条`,
      },
      selectedRowKeys: [],
      selectedRows: [],
      cateName: '一键转发',
      tagName: '视频'
    }
  },
  computed: {
    rowSelection() {
      const _this = this
      return {
        fixed: true,
        type: 'radio', //"radio"
        selectedRowKeys: this.selectedRowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
          _this.selectedRowKeys = selectedRowKeys
          _this.selectedRows = selectedRows
        },
      }
    },
  },
  methods: {
    /**
     * 获取表格数据
     */
    getData() {
      this.tableLoading = true
      listArticle({
        pageNumber: this.page.current,
        pageSize: this.page.pageSize,
        cateName: this.cateName,
        tagName: this.tagName,
        ...this.searchData,
      })
        .then((res) => {
          if (res.code === 200) {
            const { total, records } = res.body
            this.page.total = total
            this.tableData = records
          }
        })
        .finally(() => (this.tableLoading = false))
    },

    onRecommend() {
      if(this.selectedRows.length <= 0) {
        return this.$message.warning('请至少选择一条数据')
      }
      let data = {
        articleId: this.selectedRows[0].id
      }
      this.axios.post(`/api/base/article/articleIndexRecommendRecord/add`, data).then(res => {
        this.$message.success(res.message)
        this.getData()
      })
    },

    /**
     * 筛选查询
     */
    getDataFilter() {
      this.page.current = 1;
      this.getData()
    },
    /**
     * 重置
     */
    reset() {
      this.searchData = {}
      this.page.current = 1;
      this.page.pageSize = 10
      this.getData()
    },
    /**
     * 更改分页
     */
    changeTable(pagination) {
      this.page = pagination
      this.getData()
    },
    /**
     * 点击行选中
     */
    changeTableRow(record) {
      return {
        on: {
          click: (e) => {
            console.log(e)
            if (this.selectedRowKeys[0] === record.id) {
              this.selectedRowKeys = []
              this.selectedRows = []
            } else {
              this.selectedRowKeys = [record.id]
              this.selectedRows = [record]
            }
          },
        },
      }
    },
    toHandler(name) {
      const _this = this
      if (name === 'add') {
        _this.$refs.ArticleEditModal.setRowData({}, 'add', false, this.cateName, this.tagName)
        return
      }
      if (_this.selectedRows.length === 0) {
        _this.$notification.warning({
          message: '请选择一条记录',
        })
        return
      }
      switch (name) {
        case 'edit':
          _this.$refs.ArticleEditModal.setRowData(_this.selectedRows[0], 'edit', false, this.cateName, this.tagName)
          break
        case 'del':
          _this.$confirm({
            title: '删除警告',
            content: '确认要删除此条记录吗?',
            okText: '确认',
            cancelText: '取消',
            async onOk() {
              const res = await delArticle(_this.selectedRows[0].id)
              if (res.code === 200) {
                _this.$notification.success({ message: res.message })
                _this.getData()
              } else {
                _this.$notification.error({ message: res.message })
              }
            },
            onCancel() {},
          })
          break
      }
    },

    // 修改是否上架
     onStatusChange(name) {
      const that = this
      if (that.selectedRows.length === 0) {
        that.$notification.info({
          message: '请选择一条记录',
        })
        return
      }
      let nameMessage = name == 'up' ?'上架':'下架' ;
      that.$confirm({
        title: nameMessage + '警告',
        content: '确认要' + nameMessage + '吗?',
        okText: '确认',
        cancelText: '取消',
        onOk() {
          let num = {}
          if (name == 'up') {
            num = {
              id: that.selectedRows[0].id,
              flagUp: 1,
            }
          } else if (name == 'down') {
            num = {
              id: that.selectedRows[0].id,
              flagUp: 2,
            }
          }
          that.axios.post('/api/base/article/article/editFlagEnable', num).then((res) => {
            that.$notification.success({ message: res.message })
            that.getData()
          })
        },
        onCancel() {},
      })
    },
  },
  created() {
    this.getData()
  },
}
</script>

<style lang="less" scoped>
.ant-btn {
  margin-right: 8px;
  margin-bottom: 12px;
}
</style>
